.defaultDropzone {
    width: 100%;
    min-height: 250px;
    background: #e5eef5;
    border-radius: 5px;
    float: left;
  }
  
  .defaultDropzone span.dropzoneText {
    display: inline-block;
    text-align: center;
    width: 100%;
  }
  
  .dropzone:focus, .dropzone:active, .headerContent:focus {
    outline: none;
    border: none;
  }
  
  .dropzoneButton {
    display: inline-block;
    line-height: 20px;
    width: 150px;
    border-radius: 5px;
    text-transform: uppercase;
  }
  
  .dropzoneImageWrapper, .dropzoneImageWrapperOk, .dropzoneImageWrapperFail, .dropzoneImageWrapperFileToBig {
    width: 185px;
    height: 185px;
    margin-left: 15px;
    margin-top: 15px;
    border: 5px solid gray;
    border-radius: 5px;
    position: relative;
    background-color: whitesmoke;
    float: left;
  }
  
  .dropzoneImageWrapper .overlay, .dropzoneImageWrapperOk .overlay, .dropzoneImageWrapperFail .overlay {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    height: 100%;
    width: 100%;
    background-color: lightgray;
    border: 0px solid gray;
    display: inline-block;
    line-height: 100%;
  }
  
  .dropzoneImageWrapperFileToBig .overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: lightgray;
    border: 0px solid gray;
    text-align: center;
    font-weight: bolder;
  }
  
  .dropzoneImageWrapper .overlay {
    opacity: 0.3;
  }
  
  .dropzoneImageWrapper .overlay img.upload {
    width: 30px;
    height: 30px;
  }
  
  .dropzoneImageWrapperOk .overlay, .dropzoneImageWrapperFail .overlay {
    background-color: transparent;
  }
  
  .dropzoneImageWrapperOk {
    color: #4ece3d;
    border: 2px solid #4ece3d;
  }
  
  .dropzoneImageWrapperFail {
    color: #ce3d3d;
    border: 2px solid #ce3d3d;
  }
  
  .dropzoneImage {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }