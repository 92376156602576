.outsideFooter {
    min-height: 22.66275rem;
    flex-shrink: 0;
    border: 1px solid #1F244D;
    background-color: #25476a;

    padding-top: 3.45rem;
    padding-bottom: 2.47rem;
    color: white;
    border: 1px solid;




    h5 {
        color: var(--White, #FFF);
        font-family: Poppins;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 600;
        line-height: 180%;
        /* 2.025rem */
        letter-spacing: 0.045rem;
    }

    a:is(:link, :visited, :hover, :active, :focus) {
        color: #DEE4F2;
        font-family: Manrope;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 340%;
        /* 3.4rem */
        text-decoration: none;
    }
}

.footer {
    border-top: 1px solid rgba(0,0,0,.07)!important;
    /* Adjust the color to match your border-top color */
    padding-bottom: 0.5rem;
    /* pb-2 equivalent */
    width: 100%;


    .footer-menu {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
    }

    .nav-item {
        display: block;
    }

    .nav-link {
        display: inline;
        align-items: center;
        text-decoration: none;
        color: inherit;
        /* Adjust as needed */
        padding: 0.5rem 1rem;
        /* Adjust as needed */
    }

    .nav-link .fs-5 {
        font-size: 1.25rem;
        /* Adjust size as needed */
    }

    .nav-link .me-2 {
        margin-right: 0.5rem;
        /* Adjust as needed */
    }

    .nav-link .nav-label {
        margin-left: 0.25rem;
        /* Adjust as needed */
    }
}