/***** Landingpage ******/
.landingpage {
  .CTA {
    font-family: Ubuntu;
    font-size: 3.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: 4.125rem;
  }

  .CTAText {
    color: #EAEAEA;
    text-align: center;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.875rem;
  }


  .ctaCenter {
    color: var(--Text-color-black, #000);
    font-family: Ubuntu;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.625rem;
    /* 120.833% */
  }

  .ctaCenterHighlight {
    color: #9FCC2E;
    font-family: Ubuntu;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.625rem;
  }


  .howitworks {
    color: #141414;
    text-align: center;
    font-family: Ubuntu;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.03rem;
  }

  .howitworkscard {
    border-radius: 1rem;
    background: #F7F9FF;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    padding-top: 2.62rem;
    padding-bottom: 2.62rem;
    /* Space below the card */

  }

  .card {
    border-radius: 1rem;
    background: #F7F9FF;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    /* Space below the card */
  }

  .card-img-top {
    width: auto;
    height: 80px;
    margin: 0 auto 20px;
  }

  .card-body {
    text-align: center;
  }

  .card-title {
    color: #333;
    margin-bottom: 15px;
  }

  .card-text {
    color: #666;
    font-size: 0.9rem;
  }


  .startbutton {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    /* 125% */
    letter-spacing: 0.035rem;
    border-radius: 0.3125rem;
    border: 1px solid #FFF;
    background: #9FCC2E;
    padding: 1.5rem;
  }

  .arrow-container {
    display: flex;
    justify-content: center;
    /* Center arrow image horizontally */
    align-items: center;
    /* Center arrow image vertically */
    margin: 0 15px;
    /* Adjust the space between the cards and the arrows */
  }

  .bottomCTA {
    border-radius: 1.25rem;
    background: #9FCC2E;
    width: 70.14656rem;
    height: 19.46338rem;
    flex-shrink: 0;
  }

  .pictogram {
    min-height: 9rem;
  }

}

@media (max-width: 991px) {
  .arrow-container {
    display: none;
    /* Hide arrows on smaller screens where cards stack */
  }
}

@media only screen and (max-width: 768px) {

  .landingpage {
    .CTA{
      color: #000;
    }
    .CTAText {
      color: #000;

    }
  }
}